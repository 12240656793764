// TopHeader.js
import React, { useState, useEffect } from 'react';
import './AdminDashboard.css';  // Import the CSS file for styling

const TopHeader = () => {
  const [username, setUsername] = useState('Administrator');

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  return (
    <div className="top-header">
      <img src={'/Black Logo.png'} alt="Antillia Logo" className="logo" />
      <h1 className='header-antillia'>Antillia Emergency</h1>
      <h1 className='header-network'>Network</h1>
      <p className="current-user"><span className="bold">User:</span> {username}</p>
      <img src={'/Dashboard Page Image.png'} alt="AEN Support logo" className="support-logo" />
    </div>
  );
};

export default TopHeader;

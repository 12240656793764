import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import '../AdminDashboard.css';
import './WorkOrderCustomerDiscussion.css';

const WorkOrderCustomerDiscussion = () => {
  const navigate = useNavigate();
  const [workOrders, setWorkOrders] = useState([
    { id: 1, workOrderNo: 'AEN02001', clientWorkOrderNo: 'WA8826191', account: 'Total Construction Maintenance Solutions - Programmed', address: '17 Swanston Street, City of Kingston, 3194' },
    { id: 2, workOrderNo: 'AEN02002', clientWorkOrderNo: 'WA8826401', account: 'Total Construction Maintenance Solutions - CHL', address: '76 Barry Street, City of Knox' },
    { id: 3, workOrderNo: 'AEN02003', clientWorkOrderNo: 'WA8826512', account: 'Programmed Facility Management', address: '23 Lygon Street, City of Moreland, 3053' },
    { id: 4, workOrderNo: 'AEN02004', clientWorkOrderNo: 'WA8826623', account: 'Total Construction Maintenance Solutions - Programmed', address: '45 Collins Street, City of Melbourne, 3000' },
    { id: 5, workOrderNo: 'AEN02005', clientWorkOrderNo: 'WA8826734', account: 'Total Construction Maintenance Solutions - Programmed', address: '12 Chapel Street, City of Stonnington, 3181' },
    { id: 6, workOrderNo: 'AEN02006', clientWorkOrderNo: 'WA8826845', account: 'Total Construction Maintenance Solutions - Programmed', address: '78 Queen Street, City of Melbourne, 3000' },
    { id: 7, workOrderNo: 'AEN02007', clientWorkOrderNo: 'WA8826956', account: 'pattersons-build-group', address: '90 King Street, City of Melbourne, 3000' },
    { id: 8, workOrderNo: 'AEN02008', clientWorkOrderNo: 'WA8827067', account: 'Programmed Facility Management', address: '34 Spencer Street, City of Melbourne, 3000' },
    { id: 9, workOrderNo: 'AEN02009', clientWorkOrderNo: 'WA8827178', account: 'Total Construction Maintenance Solutions - Programmed', address: '56 Bourke Street, City of Melbourne, 3000' },
    { id: 10, workOrderNo: 'AEN02010', clientWorkOrderNo: 'WA8827289', account: 'Programmed Facility Management', address: '89 Elizabeth Street, City of Melbourne, 3000' },
    { id: 11, workOrderNo: 'AEN02011', clientWorkOrderNo: 'WA8827390', account: 'Total Construction Maintenance Solutions - DEF', address: '67 Collins Street, City of Melbourne, 3000' },
    { id: 12, workOrderNo: 'AEN02012', clientWorkOrderNo: 'WA8827401', account: 'pattersons-build-group', address: '101 Flinders Street, City of Melbourne, 3000' }
  ]);

  const [searchWorkOrderNo, setSearchWorkOrderNo] = useState('');
  const [searchClientWorkOrderNo, setSearchClientWorkOrderNo] = useState('');
  const [searchAccount, setSearchAccount] = useState('');
  const [searchAddress, setSearchAddress] = useState('');

  const handleWorkOrderClick = (workOrderNo) => {
    navigate(`/admin/work-order-customer-discussion/${workOrderNo}`);
  };

  const handleClearFilters = () => {
    setSearchWorkOrderNo('');
    setSearchClientWorkOrderNo('');
    setSearchAccount('');
    setSearchAddress('');
  };

  const filteredWorkOrders = workOrders.filter(order =>
    order.workOrderNo.toLowerCase().includes(searchWorkOrderNo.toLowerCase()) &&
    order.clientWorkOrderNo.toLowerCase().includes(searchClientWorkOrderNo.toLowerCase()) &&
    order.account.toLowerCase().includes(searchAccount.toLowerCase()) &&
    order.address.toLowerCase().includes(searchAddress.toLowerCase())
  );

  return (
    <>
      <AdminHeader />
      <Box p={3}>
        <h2>Work Order Customer Discussion</h2>
        <div className="search-fields-container">
          <div className="search-fields-row">
            <input
              type="text"
              placeholder="Work Order Number"
              value={searchWorkOrderNo}
              onChange={(e) => setSearchWorkOrderNo(e.target.value)}
              className="search-field"
            />
            <input
              type="text"
              placeholder="Client Work Order Number"
              value={searchClientWorkOrderNo}
              onChange={(e) => setSearchClientWorkOrderNo(e.target.value)}
              className="search-field"
            />
            <input
              type="text"
              placeholder="Account"
              value={searchAccount}
              onChange={(e) => setSearchAccount(e.target.value)}
              className="search-field"
            />
            <input
              type="text"
              placeholder="Address"
              value={searchAddress}
              onChange={(e) => setSearchAddress(e.target.value)}
              className="search-field"
            />
          </div>
          <button onClick={handleClearFilters} className="clear-filters-button">Clear Filters</button>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="work order table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Work Order Number</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Client Work Order Number</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Account</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Address</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredWorkOrders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>
                    <Button onClick={() => handleWorkOrderClick(order.workOrderNo)}>
                      {order.workOrderNo}
                    </Button>
                  </TableCell>
                  <TableCell>{order.clientWorkOrderNo}</TableCell>
                  <TableCell>{order.account}</TableCell>
                  <TableCell>{order.address}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <AdminFooter />
    </>
  );
};

export default WorkOrderCustomerDiscussion;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import '../AdminDashboard.css';
import './PurchaseOrder.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AdminFooter from '../AdminFooter';
import TextField from '@mui/material/TextField';

const PurchaseOrders = () => {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [programmedRows, setProgrammedRows] = useState([]);
    const [filteredProgrammedRows, setFilteredProgrammedRows] = useState([]);
    const [searchFields, setSearchFields] = useState({
        purchaseOrderNumber: '',
        companyName: '',
        customerName: '',
        issueDate: '',
        orderPlacedBy: ''
    });
    const [showPaid, setShowPaid] = useState(false);

    useEffect(() => {
        const fetchPurchaseOrders = async (page = 0, size = 1000) => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders?page=${page}&size=${size}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch purchase orders');
                }

                const responseData = await response.json();
                const data = responseData.content.filter(order => order.paymentStatus === (showPaid ? 'PAID' : 'UNPAID'));

                const structuredData = data.map(order => ({
                    purchaseOrderNumber: order.purchaseOrderNumber || 'N/A',
                    companyName: order.companyName || 'N/A',
                    customerName: order.customerName || 'N/A',
                    issueDate: order.issueDate || 'N/A',
                    orderPlacedBy: order.orderPlacedByName || 'N/A',
                    totalCost: order.totalCost !== null ? order.totalCost.toFixed(2) : 'N/A'
                }));

                setRows(structuredData);
                setFilteredRows(structuredData);
            } catch (error) {
                console.error('Error fetching purchase orders:', error);
            }
        };

        const fetchProgrammedPurchaseOrders = async (page = 0, size = 1000) => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders/programmed?page=${page}&size=${size}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch programmed purchase orders');
                }

                const responseData = await response.json();
                const data = responseData.content.filter(order => order.paymentStatus === (showPaid ? 'PAID' : 'UNPAID'));

                const structuredData = data.map(order => ({
                    purchaseOrderNumber: order.purchaseOrderNumber || 'N/A',
                    companyName: order.companyName || 'N/A',
                    customerName: order.customerName || 'N/A',
                    issueDate: order.issueDate || 'N/A',
                    orderPlacedBy: order.orderPlacedByName || 'N/A',
                    totalCost: order.totalCost !== null ? order.totalCost.toFixed(2) : 'N/A'
                }));

                setProgrammedRows(structuredData);
                setFilteredProgrammedRows(structuredData);
            } catch (error) {
                console.error('Error fetching programmed purchase orders:', error);
            }
        };

        fetchProgrammedPurchaseOrders();
        fetchPurchaseOrders();
    }, [showPaid]);

    useEffect(() => {
        const filtered = rows.filter(row =>
            row.purchaseOrderNumber.toLowerCase().includes(searchFields.purchaseOrderNumber.toLowerCase()) &&
            row.companyName.toLowerCase().includes(searchFields.companyName.toLowerCase()) &&
            row.customerName.toLowerCase().includes(searchFields.customerName.toLowerCase()) &&
            row.issueDate.toLowerCase().includes(searchFields.issueDate.toLowerCase()) &&
            row.orderPlacedBy.toLowerCase().includes(searchFields.orderPlacedBy.toLowerCase())
        );
        setFilteredRows(filtered);
    }, [searchFields, rows]);

    useEffect(() => {
        const filtered = programmedRows.filter(row =>
            row.purchaseOrderNumber.toLowerCase().includes(searchFields.purchaseOrderNumber.toLowerCase()) &&
            row.companyName.toLowerCase().includes(searchFields.companyName.toLowerCase()) &&
            row.customerName.toLowerCase().includes(searchFields.customerName.toLowerCase()) &&
            row.issueDate.toLowerCase().includes(searchFields.issueDate.toLowerCase()) &&
            row.orderPlacedBy.toLowerCase().includes(searchFields.orderPlacedBy.toLowerCase())
        );
        setFilteredProgrammedRows(filtered);
    }, [searchFields, programmedRows]);

    const handleSearchChange = (e) => {
        setSearchFields({ ...searchFields, [e.target.name]: e.target.value });
    };

    const clearFilters = () => {
        setSearchFields({
            purchaseOrderNumber: '',
            companyName: '',
            customerName: '',
            issueDate: '',
            orderPlacedBy: ''
        });
    };

    const togglePaidFilter = () => {
        setShowPaid(prev => !prev);
    };

    return (
        <>
            <AdminHeader />
            <Link to="/admin/purchase-orders/add-new-purchase-order" className="link-button">
                <div className="container-new-purchase-order">
                    <img src={"/PO Logo.png"} alt="Purchase Order Logo" className="purchase-order-icon" />
                    <button>Create New Purchase Order</button>
                </div>
            </Link>
            <br />

            {/* Search Fields */}
            <div className="search-fields">
                <TextField className="search-field" name="purchaseOrderNumber" value={searchFields.purchaseOrderNumber} onChange={handleSearchChange} label="Purchase Order Number" variant="outlined" />
                <TextField className="search-field" name="companyName" value={searchFields.companyName} onChange={handleSearchChange} label="Company Name" variant="outlined" />
                <TextField className="search-field" name="customerName" value={searchFields.customerName} onChange={handleSearchChange} label="Customer Name" variant="outlined" />
                <TextField className="search-field" name="issueDate" value={searchFields.issueDate} onChange={handleSearchChange} label="Issue Date" variant="outlined" />
                <TextField className="search-field" name="orderPlacedBy" value={searchFields.orderPlacedBy} onChange={handleSearchChange} label="Order Placed By" variant="outlined" />
            </div>
            <div>
                <button className="clear-filter" onClick={clearFilters}>Clear Filters</button>
            </div>

            {/* Toggle Paid/Unpaid Purchase Orders */}
            <div style={{ marginTop: '10px' }}>
                <Button
                    variant="contained"
                    onClick={togglePaidFilter}
                    sx={{
                        backgroundColor: showPaid ? '#005b5b' : '#e0e0e0',
                        color: showPaid ? 'white' : 'black',
                        padding: '10px 20px',
                        textTransform: 'none',
                        borderRadius: '8px',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        ml: 3,  // This adds a right margin

                        '&:hover': {
                            backgroundColor: showPaid ? '#54C4C1' : '#54C4C1',
                        },
                    }}
                >
                    {showPaid ? "Show Open Purchase Orders" : "Show Closed Purchase Orders"}
                </Button>
            </div>

            {/* Programmed Purchase Orders Table */}
            <h2 style={{ textAlign: 'center', marginTop: '20px' }}>Programmed Purchase Orders</h2>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Purchase Order No</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Company Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Customer Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Issue Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Order Placed By</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Total Cost</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredProgrammedRows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell align="left">
                                    <Link to={`/admin/programmed-purchase-orders/${row.purchaseOrderNumber}`}>{row.purchaseOrderNumber}</Link>
                                </TableCell>
                                <TableCell align="left">{row.companyName}</TableCell>
                                <TableCell align="left">{row.customerName}</TableCell>
                                <TableCell align="left">{row.issueDate}</TableCell>
                                <TableCell align="left">{row.orderPlacedBy}</TableCell>
                                <TableCell align="left">{row.totalCost}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <br />

            {/* Other Purchase Orders Table */}
            <h2 style={{ textAlign: 'center' }}>Other Purchase Orders</h2>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Purchase Order No</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Company Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Customer Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Issue Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Order Placed By</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Total Cost</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell align="left">
                                    <Link to={`/admin/purchase-orders/${row.purchaseOrderNumber}`}>{row.purchaseOrderNumber}</Link>
                                </TableCell>
                                <TableCell align="left">{row.companyName}</TableCell>
                                <TableCell align="left">{row.customerName}</TableCell>
                                <TableCell align="left">{row.issueDate}</TableCell>
                                <TableCell align="left">{row.orderPlacedBy}</TableCell>
                                <TableCell align="left">{row.totalCost}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <br /><br /><br />
            <p className="centered-text">Having issues? Contact IT Technical Support on <span className="phone-number">0405 236 236</span></p>
            <AdminFooter />
        </>
    );
};

export default PurchaseOrders;

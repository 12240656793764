import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';
import Button from '@mui/material/Button'; // Import Button from MUI
import './PurchaseOrderView.css';

const PurchaseOrderView = () => {
  const { purchaseOrderNumber } = useParams();
  const [purchaseOrder, setPurchaseOrder] = useState(null);

  useEffect(() => {
    const fetchPurchaseOrder = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders/${purchaseOrderNumber}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch purchase order details');
        }

        const data = await response.json();
        setPurchaseOrder(data);
      } catch (error) {
        console.error('Error fetching purchase order details:', error);
      }
    };

    fetchPurchaseOrder();
  }, [purchaseOrderNumber]);

  const handleViewInvoice = async (purchaseOrderNumber) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders/${purchaseOrderNumber}/invoice-url`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch invoice URL');
      }

      const signedUrl = await response.text();
      window.open(signedUrl, '_blank'); // Open the invoice in a new tab
    } catch (error) {
      console.error('Error fetching invoice URL:', error);
    }
  };

  if (!purchaseOrder) {
    return <p>Loading...</p>;
  }

  // Remove everything after the dash
  const workOrderNumber = purchaseOrderNumber.split('-')[0];

  return (
    <>
      <AdminHeader />
      <div className="purchase-order-view">
        <h2>Purchase Order Details</h2>
        <div className="details-container">
          <h3>
            Work Order No: <span style={{ color: '#005B5B' }}>{workOrderNumber}</span>
          </h3>
          <h3>
            Purchase Order No: <span style={{ color: '#005B5B' }}>{purchaseOrder.purchaseOrderNumber}</span>
          </h3>
          <div className="divider"></div>

          <h3>Supplier Details</h3>
          <p><strong>Company Name:</strong> {purchaseOrder.companyName}</p>
          <p><strong>Company Phone:</strong> {purchaseOrder.companyPhone}</p>
          <p><strong>Company Email:</strong> {purchaseOrder.companyEmail}</p>
          <p><strong>Company ABN:</strong> {purchaseOrder.companyABN}</p>
          <div className="divider"></div>

          <h3>Customer Details</h3>
          <p><strong>Customer Name:</strong> {purchaseOrder.customerName}</p>
          <p><strong>Customer Address:</strong> {purchaseOrder.customerAddress}</p>
          <p><strong>Customer Contact:</strong> {purchaseOrder.customerContact}</p>
          <div className="divider"></div>

          <h3>Dates</h3>
          <p><strong>Issue Date:</strong> {purchaseOrder.issueDate}</p>
          <p><strong>Start Date:</strong> {purchaseOrder.startDate || 'N/A'}</p>
          <p><strong>Finish Date:</strong> {purchaseOrder.finishDate || 'N/A'}</p>
          <div className="divider"></div>

          {/* New Final Invoice Total section */}
          <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>Final Invoice Total</h3>
          <p style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
            {purchaseOrder.finalInvoiceTotal ? `$${purchaseOrder.finalInvoiceTotal}` : 'Invoice not added'}
          </p>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#005b5b',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#004a4a',
              },
            }}
            onClick={() => handleViewInvoice(purchaseOrder.purchaseOrderNumber)}
          >
            View Attached Invoice
          </Button>
          <div className="divider"></div>

          <h3>Items</h3>
          <table className="items-table">
            <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>Ext Price</th>
                <th>GST</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {purchaseOrder.items && purchaseOrder.items.length > 0 ? (
                purchaseOrder.items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.code}</td>
                    <td>{item.description}</td>
                    <td>{item.qty}</td>
                    <td>{item.unitPrice.toFixed(2)}</td>
                    <td>{item.extPrice.toFixed(2)}</td>
                    <td>{item.gst.toFixed(2)}</td>
                    <td>{item.total.toFixed(2)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" style={{ textAlign: 'center' }}>No items added</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="totals-section">
            <table>
              <tbody>
                <tr>
                  <td><strong>SUBTOTAL</strong></td>
                  <td>${purchaseOrder.items.reduce((acc, item) => acc + item.extPrice, 0).toFixed(2)}</td>
                </tr>
                <tr>
                  <td><strong>GST</strong></td>
                  <td>${purchaseOrder.items.reduce((acc, item) => acc + item.gst, 0).toFixed(2)}</td>
                </tr>
                <tr>
                  <td><strong>TOTAL</strong></td>
                  <td>${purchaseOrder.items.reduce((acc, item) => acc + item.total, 0).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="divider"></div>

          <h3>Order Placed By</h3>
          <p><strong>Name:</strong> {purchaseOrder.orderPlacedByName}</p>
          <p><strong>Position:</strong> {purchaseOrder.orderPlacedByPosition}</p>
          <p><strong>Contact Number:</strong> {purchaseOrder.orderPlacedByContactNumber}</p>
          <p><strong>Email:</strong> {purchaseOrder.orderPlacedByEmail}</p>
          <div className="divider"></div>

          <h3>Scope of Works</h3>
          <div className="scope-of-works">
          <p><strong>Make Safe:</strong> {purchaseOrder.makeSafe}</p>
          <p><strong>Notes:</strong> {purchaseOrder.notes}</p>
          </div>
        </div>
      </div>
      <AdminFooter />
    </>
  );
};

export default PurchaseOrderView;

import React from 'react';
import './AdminFooter.css';  // Import CSS for styling the footer

const AdminFooter = () => {
  return (
    <footer className="admin-footer">
      <p>© {new Date().getFullYear()} Antillia Emergency Network Pty Ltd. All rights reserved.</p>
      <p>Terms of Service | Privacy Policy</p>
    </footer>
  );
};

export default AdminFooter;

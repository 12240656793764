import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import '../AdminDashboard.css';
import './CustomerDiscussionDetails.css';

const CustomerDiscussionDetails = () => {
  const { workOrderNo } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const [discussions, setDiscussions] = useState([]);
  const [techDiscussions, setTechDiscussions] = useState([]);
  const [currentDiscussion, setCurrentDiscussion] = useState('');
  const [currentTechDiscussion, setCurrentTechDiscussion] = useState('');
  const [addedBy, setAddedBy] = useState(null);
  const [spokenTo, setSpokenTo] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editText, setEditText] = useState('');
  const [editType, setEditType] = useState(''); // 'customer' or 'tech'

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setEditIndex(null); // Reset edit state when tab changes
    setEditText('');
    setEditType('');
  };

  const handleAddDiscussion = () => {
    const newDiscussion = {
      text: currentDiscussion,
      timestamp: new Date().toLocaleString(),
      addedBy: addedBy?.label,
      spokenTo,
      edits: []
    };
    setDiscussions([...discussions, newDiscussion]);
    setCurrentDiscussion('');
    setAddedBy(null);
    setSpokenTo('');
  };

  const handleAddTechDiscussion = () => {
    const newTechDiscussion = {
      text: currentTechDiscussion,
      timestamp: new Date().toLocaleString(),
      addedBy: addedBy?.label,
      edits: []
    };
    setTechDiscussions([...techDiscussions, newTechDiscussion]);
    setCurrentTechDiscussion('');
    setAddedBy(null);
  };

  const handleEditDiscussion = (index, type) => {
    if (type === 'customer') {
      setEditText(discussions[index].text);
    } else {
      setEditText(techDiscussions[index].text);
    }
    setEditIndex(index);
    setEditType(type);
  };

  const handleSaveEditDiscussion = () => {
    if (editType === 'customer') {
      const updatedDiscussions = [...discussions];
      updatedDiscussions[editIndex].text = editText;
      updatedDiscussions[editIndex].edits.push(new Date().toLocaleString());
      setDiscussions(updatedDiscussions);
    } else {
      const updatedTechDiscussions = [...techDiscussions];
      updatedTechDiscussions[editIndex].text = editText;
      updatedTechDiscussions[editIndex].edits.push(new Date().toLocaleString());
      setTechDiscussions(updatedTechDiscussions);
    }
    setEditIndex(null);
    setEditText('');
    setEditType('');
  };

  const renderDiscussion = (discussion, index, type) => (
    <Paper key={index} elevation={2} className="discussion-paper">
      <strong>{discussion.timestamp}</strong>
      {discussion.addedBy && <div><strong>Added by:</strong> {discussion.addedBy}</div>}
      {discussion.spokenTo && <div><strong>Spoken to:</strong> {discussion.spokenTo}</div>}
      {discussion.edits.map((editTimestamp, i) => (
        <div key={i} className="edit-timestamp">Last edit: {editTimestamp}</div>
      ))}
      {editIndex === index && editType === type ? (
        <>
          <TextField
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
            multiline
            rows={6} // Increase rows for editing
            variant="outlined"
            fullWidth
            InputProps={{
              className: 'textarea'
            }}
          />
          <Button onClick={handleSaveEditDiscussion} className="save-button">
            Save
          </Button>
        </>
      ) : (
        <>
          <p className="discussion-text">{discussion.text}</p>
          <Button onClick={() => handleEditDiscussion(index, type)} className="edit-button">
            Edit
          </Button>
        </>
      )}
    </Paper>
  );

  const userOptions = [
    { value: 'sam', label: 'Sam' },
    { value: 'aji', label: 'Aji' }
  ];

  return (
    <>
      <AdminHeader />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <h2 className="work-order-heading">Work Order Details for {workOrderNo}</h2>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="work order details tabs">
          <Tab label="Add Customer Discussion" />
          <Tab label="Add Technician Discussion" />
        </Tabs>
        {selectedTab === 0 && (
          <Box p={3}>
            <Paper elevation={3} className="input-paper">
              <TextField
                label="New Customer Discussion"
                multiline
                rows={16} // Increase rows for new discussions
                value={currentDiscussion}
                onChange={(e) => setCurrentDiscussion(e.target.value)}
                variant="outlined"
                fullWidth
                InputProps={{
                  className: 'textarea'
                }}
              />
              <div className="short-fields-container">
                <Select
                  options={userOptions}
                  value={addedBy}
                  onChange={setAddedBy}
                  placeholder="Added by"
                  className="short-field select-field"
                  classNamePrefix="react-select"
                />
                <TextField
                  label="Spoken to"
                  value={spokenTo}
                  onChange={(e) => setSpokenTo(e.target.value)}
                  variant="outlined"
                  className="short-field spoken-to-field"
                />
              </div>
              <Button onClick={handleAddDiscussion} className="add-button">
                Add Customer Discussion
              </Button>
              <Box mt={3}>
                {discussions.map((discussion, index) => renderDiscussion(discussion, index, 'customer'))}
              </Box>
            </Paper>
          </Box>
        )}
        {selectedTab === 1 && (
          <Box p={3}>
            <Paper elevation={3} className="input-paper">
              <TextField
                label="New Technician Discussion"
                multiline
                rows={16} // Increase rows for new tech discussions
                value={currentTechDiscussion}
                onChange={(e) => setCurrentTechDiscussion(e.target.value)}
                variant="outlined"
                fullWidth
                InputProps={{
                  className: 'textarea'
                }}
              />
              <div className="short-fields-container">
                <Select
                  options={userOptions}
                  value={addedBy}
                  onChange={setAddedBy}
                  placeholder="Added by"
                  className="short-field select-field"
                  classNamePrefix="react-select"
                />
              </div>
              <Button onClick={handleAddTechDiscussion} className="add-button">
                Add Technician Discussion
              </Button>
              <Box mt={3}>
                {techDiscussions.map((discussion, index) => renderDiscussion(discussion, index, 'tech'))}
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
      <AdminFooter />
    </>
  );
};

export default CustomerDiscussionDetails;

import React, { useState } from 'react';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        const emailInput = e.target.value;
        setEmail(emailInput);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(emailInput) && emailInput) {
            setEmailError('Invalid email format');
        } else {
            setEmailError('');
        }
    };

    const handlePasswordChange = (e) => setPassword(e.target.value);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!emailError) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ email, password })
                });

                if (response.status === 403) {
                    setLoginError('Incorrect username or password');
                } else if (response.ok) {
                    const data = await response.json();
                    localStorage.setItem('token', data.jwt);
                    localStorage.setItem('username', data.username);
                    localStorage.setItem('email', data.email);

                    // Set a timeout for automatic logout after 6 hours
                    setTimeout(() => {
                        localStorage.setItem('username', data.username);
                        localStorage.setItem('email', data.email);
                        localStorage.removeItem('token');
                        toast.error('Your session has expired. Please log in again.', {
                            autoClose: false,  // Keep the toast open
                            closeOnClick: false,
                            draggable: false,
                            className: 'custom-toast',
                        });
                        navigate('/');
                    }, 6 * 60 * 60 * 1000); // 6 hours in milliseconds

                    navigate('/admin');
                } else {
                    const data = await response.json();
                    setLoginError(`Login failed: ${data.message}`);
                }
            } catch (error) {
                setLoginError('An error occurred during login');
                console.error('An error occurred during login:', error);
            }
        } else {
            console.log('Fix errors before submitting.');
        }
    };

    return (
        <div className="login-container">
            <ToastContainer />
            {loginError && (
                <div className="error-modal">
                    <div className="error-modal-content">
                        <span className="close-button" onClick={() => setLoginError('')}>&times;</span>
                        <p>{loginError}</p>
                    </div>
                </div>
            )}
            <form onSubmit={handleSubmit} className="login-form">
                <div className="login-header">
                    <img src="LogoTransparent.png" alt="Main Logo" className="main-logo" />
                    <img src="Login Page Image.png" alt="Secondary Logo" className="secondary-logo" />
                    <h1 className="login-antillia">Antillia Emergency</h1>
                    <h1 className="login-network">Network</h1>
                    <p className="login-welcome">Welcome</p>
                    <p className="login-description">Log into Antillia Emergency Network to continue to Admin Portal</p>
                </div>
                <div className="input-group">
                    <input type="email" value={email} onChange={handleEmailChange} required placeholder="Email Address" id="placeholder-input" />
                    {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
                </div>
                <div className="input-group">
                    <input type="password" value={password} onChange={handlePasswordChange} required placeholder="Password" id="placeholder-input" />
                </div>
                <div className="login-footer">
                    <a href="#">Forgot password?</a>
                </div>
                <button type="submit" className="login-button">LOGIN</button>
            </form>
        </div>
    );
}

export default LoginPage;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';
import '../AdminDashboard.css';
import './InvoicesPage.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const InvoicesPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [programmedWorkOrders, setProgrammedWorkOrders] = useState([]);
  const [regularWorkOrders, setRegularWorkOrders] = useState([]);
  const [programmedPurchaseOrders, setProgrammedPurchaseOrders] = useState([]);
  const [regularPurchaseOrders, setRegularPurchaseOrders] = useState([]);
  const [invoicedProgrammedWorkOrders, setInvoicedProgrammedWorkOrders] = useState([]);
  const [invoicedRegularWorkOrders, setInvoicedRegularWorkOrders] = useState([]);

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    try {
      const token = localStorage.getItem('token');

      const [
        programmedWorkOrdersResponse,
        regularWorkOrdersResponse,
        programmedPurchaseOrdersResponse,
        regularPurchaseOrdersResponse,
        invoicedProgrammedWorkOrdersResponse,
        invoicedRegularWorkOrdersResponse,
      ] = await Promise.all([
        fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/status/READY_TO_INVOICE`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }),
        fetch(`${process.env.REACT_APP_API_URL}/admin/work-orders/status/READY_TO_INVOICE`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }),
        fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders/programmed`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }),
        fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }),
        fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/status/INVOICED`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }),
        fetch(`${process.env.REACT_APP_API_URL}/admin/work-orders/status/INVOICED`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }),
      ]);

      setProgrammedWorkOrders(await programmedWorkOrdersResponse.json());
      setRegularWorkOrders(await regularWorkOrdersResponse.json());
      setProgrammedPurchaseOrders((await programmedPurchaseOrdersResponse.json()).content);
      setRegularPurchaseOrders((await regularPurchaseOrdersResponse.json()).content);
      setInvoicedProgrammedWorkOrders(await invoicedProgrammedWorkOrdersResponse.json());
      setInvoicedRegularWorkOrders(await invoicedRegularWorkOrdersResponse.json());
    } catch (error) {
      console.error('Error fetching work orders or purchase orders:', error);
    }
  };

  const handleStatusChange = async (workOrderNumber, status, isProgrammed) => {
    try {
      const token = localStorage.getItem('token');
      const apiUrl = isProgrammed
        ? `${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/status/${workOrderNumber}`
        : `${process.env.REACT_APP_API_URL}/admin/work-orders/status/${workOrderNumber}`;

      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: status }), // Ensure the status is sent as a string in the payload
      });

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      // Refresh the data after status change
      fetchAllData();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const renderPurchaseOrders = (workOrderNumber, purchaseOrders, isProgrammed) => {
    const filteredPOs = purchaseOrders.filter(po => {
      const poWorkOrderNumber = isProgrammed ? po.programmedWorkOrder?.workOrderNumber : po.workOrder?.workOrderNumber;
      return poWorkOrderNumber === workOrderNumber;
    });

    if (filteredPOs.length === 0) {
      return <div>No Purchase Orders Found</div>;
    }

    return filteredPOs.map((po, index) => (
      <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
        {index + 1}. {po.purchaseOrderNumber}
        {po.paymentStatus === 'PAID' ? (
          <CheckCircleIcon style={{ color: 'green', marginLeft: '8px' }} />
        ) : (
          <CancelIcon style={{ color: 'red', marginLeft: '8px' }} />
        )}
      </div>
    ));
  };

  const renderWorkOrders = (workOrders, purchaseOrders, isProgrammed) => {
    if (!Array.isArray(workOrders) || workOrders.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4} align="left">No work orders available.</TableCell>
        </TableRow>
      );
    }

    return workOrders.map((workOrder, index) => (
      <TableRow key={index}>
        <TableCell align="left" style={{ width: '50px', border: '1px solid #ddd' }}>{index + 1}</TableCell>
        <TableCell align="left" style={{ border: '1px solid #ddd' }}>
          <Link to={`/admin/${isProgrammed ? 'programmed-work-orders' : 'work-orders'}/${workOrder.workOrderNumber}`}>
            {workOrder.workOrderNumber}
          </Link>
        </TableCell>
        <TableCell align="left" style={{ border: '1px solid #ddd' }}>
          {renderPurchaseOrders(workOrder.workOrderNumber, purchaseOrders, isProgrammed)}
        </TableCell>
        <TableCell align="center" style={{ border: '1px solid #ddd' }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#005b5b',
              color: 'white',
              padding: '4px 12px',
              fontSize: '12px',
              borderRadius: '8px',
              minWidth: '100px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              textTransform: 'none',
            }}
            onClick={() => handleStatusChange(workOrder.workOrderNumber, 'INVOICED', isProgrammed)}
          >
            Move to Invoiced
          </Button>
        </TableCell>
        <TableCell align="center" style={{ border: '1px solid #ddd' }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#d9534f',
              color: 'white',
              padding: '4px 12px',
              fontSize: '12px',
              borderRadius: '8px',
              minWidth: '100px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              textTransform: 'none',
            }}
            onClick={() => handleStatusChange(workOrder.workOrderNumber, 'NEW', isProgrammed)}
          >
            Move to Work Order
          </Button>
        </TableCell>
      </TableRow>
    ));
  };

  const renderInvoicedWorkOrders = (workOrders, isProgrammed) => {
    if (!Array.isArray(workOrders) || workOrders.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4} align="left">No work orders available.</TableCell>
        </TableRow>
      );
    }

    return workOrders.map((workOrder, index) => (
      <TableRow key={index}>
        <TableCell align="left" style={{ width: '50px', border: '1px solid #ddd' }}>{index + 1}</TableCell>
        <TableCell align="left" style={{ border: '1px solid #ddd' }}>
          <Link to={`/admin/${isProgrammed ? 'programmed-work-orders' : 'work-orders'}/${workOrder.workOrderNumber}`}>
            {workOrder.workOrderNumber}
          </Link>
        </TableCell>
        <TableCell align="center" style={{ border: '1px solid #ddd' }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#28a745',
              color: 'white',
              padding: '4px 12px',
              fontSize: '12px',
              borderRadius: '8px',
              minWidth: '100px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              textTransform: 'none',
              marginBottom: '8px',
            }}
            onClick={() => handleStatusChange(workOrder.workOrderNumber, 'READY_TO_INVOICE', isProgrammed)}
          >
            Move Back to Ready to Invoice
          </Button>
        </TableCell>
        <TableCell align="center" style={{ border: '1px solid #ddd' }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#005b5b',
              color: 'white',
              padding: '4px 12px',
              fontSize: '12px',
              borderRadius: '8px',
              minWidth: '100px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              textTransform: 'none',
            }}
            onClick={() => handleStatusChange(workOrder.workOrderNumber, 'COMPLETED_JOB', isProgrammed)}
          >
            Move to Completed
          </Button>
        </TableCell>
      </TableRow>
    ));
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <AdminHeader />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="invoices tabs">
          <Tab label={`Ready to Invoice`} />
          <Tab label={`Invoiced`} />
        </Tabs>
        {selectedTab === 0 && (
          <Box p={3}>
            <h2 style={{ textAlign: 'center' }}>Ready to Invoice - Programmed</h2>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ width: '50px', fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>#</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Work Order No</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Purchase Orders</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move to Invoiced</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move to Work Order</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderWorkOrders(programmedWorkOrders, programmedPurchaseOrders, true)}
                </TableBody>
              </Table>
            </TableContainer>

            <h2 style={{ marginTop: '40px', textAlign: 'center' }}>Ready to Invoice - Other</h2>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ width: '50px', fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>#</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Work Order No</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Purchase Orders</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move to Invoiced</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move to Work Order</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderWorkOrders(regularWorkOrders, regularPurchaseOrders, false)}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {selectedTab === 1 && (
          <Box p={3}>
            <h2 style={{ textAlign: 'center' }}>Invoiced - Programmed</h2>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ width: '50px', fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>#</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Work Order No</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move Back to Ready to Invoice</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move to Completed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderInvoicedWorkOrders(invoicedProgrammedWorkOrders, true)}
                </TableBody>
              </Table>
            </TableContainer>

            <h2 style={{ marginTop: '40px', textAlign: 'center' }}>Invoiced - Other</h2>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ width: '50px', fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>#</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Work Order No</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move Back to Ready to Invoice</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', border: '1px solid #ddd' }}>Move to Completed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderInvoicedWorkOrders(invoicedRegularWorkOrders, false)}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
      <AdminFooter />
    </>
  );
};

export default InvoicesPage;

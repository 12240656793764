import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from './AdminHeader';
import './AdminDashboard.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AdminFooter from './AdminFooter';
import TextField from '@mui/material/TextField';
import moment from 'moment';

const WorkOrders = () => {
    const [searchFields, setSearchFields] = useState({
        WorkOrder: '',
        ClientWorkOrder: '',
        ClientPurchaseOrder: '',
        JobAddress: '',
        Account: ''
    });

    const [workOrders, setWorkOrders] = useState([]);
    const [programmedWorkOrders, setProgrammedWorkOrders] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [filteredProgrammedRows, setFilteredProgrammedRows] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchWorkOrders = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/work-orders`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    const newWorkOrders = data
                        .filter(order => order.status === 'NEW' && order.account !== 'programmed-facility-management')
                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));  // Sort by createdAt descending
                    setWorkOrders(newWorkOrders);
                    setFilteredRows(newWorkOrders);
                } else {
                    console.error('Failed to fetch work orders');
                }
            } catch (error) {
                console.error('Error fetching work orders:', error);
                setError('Error fetching work orders');
            }
        };

        const fetchProgrammedWorkOrders = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-work-orders`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const programmedData = await response.json();
                    const newProgrammedWorkOrders = programmedData
                        .filter(order => order.status === 'NEW')
                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));  // Sort by createdAt descending
                    setProgrammedWorkOrders(newProgrammedWorkOrders);
                    setFilteredProgrammedRows(newProgrammedWorkOrders);
                } else {
                    console.error('Failed to fetch programmed work orders:', response.status);
                }
            } catch (error) {
                console.error('Error fetching programmed work orders:', error);
            }
        };

        fetchWorkOrders();
        fetchProgrammedWorkOrders();
    }, []);

    useEffect(() => {
        setFilteredRows(workOrders.filter(row =>
            row.workOrderNumber.toLowerCase().includes(searchFields.WorkOrder.toLowerCase()) &&
            row.jobNumber.toLowerCase().includes(searchFields.ClientWorkOrder.toLowerCase()) &&
            row.poNumber.toLowerCase().includes(searchFields.ClientPurchaseOrder.toLowerCase()) &&
            row.address.toLowerCase().includes(searchFields.JobAddress.toLowerCase()) &&
            (row.account ? row.account.toLowerCase().includes(searchFields.Account.toLowerCase()) : true)
        ));

        setFilteredProgrammedRows(programmedWorkOrders.filter(row =>
            row.workOrderNumber.toLowerCase().includes(searchFields.WorkOrder.toLowerCase()) &&
            row.jobNumber.toLowerCase().includes(searchFields.ClientWorkOrder.toLowerCase()) &&
            row.poNumber.toLowerCase().includes(searchFields.ClientPurchaseOrder.toLowerCase()) &&
            row.address.toLowerCase().includes(searchFields.JobAddress.toLowerCase()) &&
            (row.contactName ? row.contactName.toLowerCase().includes(searchFields.Account.toLowerCase()) : true)
        ));
    }, [searchFields, workOrders, programmedWorkOrders]);

    const handleSearchChange = (e) => {
        setSearchFields({ ...searchFields, [e.target.name]: e.target.value });
    };

    const clearFilters = () => {
        setSearchFields({
            WorkOrder: '',
            ClientWorkOrder: '',
            ClientPurchaseOrder: '',
            JobAddress: '',
            Account: ''
        });
    };

    return (
        <>
            <AdminHeader />
            <Link to="/admin/work-orders/add-new-work-order" className="link-button">
                <div className="container-new-work-order">
                    <img src={"/WO Logo.jpg"} alt="Work Order Logo" className="Work-order-icon" />
                    <button>Create New Work Order</button>
                </div>
            </Link>
            <br />
            <div className="search-fields" style={{ marginTop: '20px' }}>
                <TextField className="search-field" name="WorkOrder" value={searchFields.WorkOrder} onChange={handleSearchChange} label="Work Order Number" variant="outlined" />
                <TextField className="search-field" name="ClientWorkOrder" value={searchFields.ClientWorkOrder} onChange={handleSearchChange} label="Client Work Order Number" variant="outlined" />
                <TextField className="search-field" name="ClientPurchaseOrder" value={searchFields.ClientPurchaseOrder} onChange={handleSearchChange} label="Client Purchase Order Number" variant="outlined" />
                <TextField className="search-field" name="JobAddress" value={searchFields.JobAddress} onChange={handleSearchChange} label="Job Address" variant="outlined" />
                <TextField className="search-field" name="Account" value={searchFields.Account} onChange={handleSearchChange} label="Account" variant="outlined" />
            </div>
            <div>
                <button className="clear-filter" onClick={clearFilters}>Clear Filters</button>
            </div>
            {error && <p>{error}</p>}

            {/* Programmed Work Orders Table */}
            <h2 style={{ textAlign: 'center' }}>Programmed Facility Management</h2>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>PO&nbsp;Received</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Work&nbsp;Order</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Job&nbsp;Address</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left" className="table-cell-wrap">Details&nbsp;of&nbsp;Incident/Event</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Site&nbsp;Contact&nbsp;Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Site&nbsp;Contact&nbsp;No</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Target&nbsp;Finish</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredProgrammedRows.map((row, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { borderBottom: '1px solid rgba(224, 224, 224, 1)' } }}>
                                <TableCell component="th" scope="row">
                                    {moment(row.dateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm')}
                                </TableCell>
                                <TableCell align="left">
                                    <Link to={`/admin/programmed-work-orders/${row.workOrderNumber}`}>
                                        {row.workOrderNumber}
                                    </Link>
                                </TableCell>
                                <TableCell align="left" className="table-cell-wrap">{row.address}</TableCell>
                                <TableCell align="left" className="table-cell-wrap">{row.detailsOfIncident}</TableCell>
                                <TableCell align="left">{row.contactName}</TableCell>
                                <TableCell align="left">{row.contactPhone}</TableCell>
                                <TableCell align="left">{moment(row.targetFinish, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br /><br /><br />

            {/* Other Clients Work Orders Table */}
            <h2 style={{ textAlign: 'center' }}>Other Clients</h2>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>PO&nbsp;Received</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Work&nbsp;Order</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Job&nbsp;Address</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Account</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Client&nbsp;Work&nbsp;Order&nbsp;(No.)</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Client&nbsp;Purchase&nbsp;Order&nbsp;(No.)</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Site&nbsp;Contact&nbsp;-&nbsp;Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Site Contact&nbsp;-&nbsp;Contact&nbsp;Number</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="left">Target&nbsp;Finish</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows.map((row, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { borderBottom: '1px solid rgba(224, 224, 224, 1)' } }}>
                                <TableCell component="th" scope="row">
                                    {moment(row.dateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm')}
                                </TableCell>
                                <TableCell align="left">
                                    <Link to={`/admin/work-orders/${row.workOrderNumber}`}>
                                        {row.workOrderNumber}
                                    </Link>
                                </TableCell>
                                <TableCell align="left" className="table-cell-wrap">{row.address}</TableCell>
                                <TableCell align="left">{row.account}</TableCell>
                                <TableCell align="left">{row.jobNumber}</TableCell>
                                <TableCell align="left">{row.poNumber}</TableCell>
                                <TableCell align="left">{row.contactName}</TableCell>
                                <TableCell align="left">{row.contactMobile}</TableCell>
                                <TableCell align="left">{moment(row.targetFinish, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <br /><br /><br />
            <p className="centered-text">Having issues? Contact IT Technical Support on <span className="phone-number">0405 236 236</span></p>
            <AdminFooter />
        </>
    );
};

export default WorkOrders;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import AdminHeader from './AdminHeader';
import AdminFooter from './AdminFooter';
import './AdminDashboard.css';
import './AddNewWorkOrder.css';

const libraries = ['places'];

const AddNewWorkOrder = () => {
    const [dateTime, setDateTime] = useState(new Date());
    const [account, setAccount] = useState(null);
    const [jobNumber, setJobNumber] = useState('');
    const [poNumber, setPoNumber] = useState('');
    const [address, setAddress] = useState('');
    const [manualAddress, setManualAddress] = useState({
        street: '',
        city: '',
        state: '',
        zip: '',
    });
    const [isManualAddress, setIsManualAddress] = useState(false);
    const [contactName, setContactName] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [contactMobile, setContactMobile] = useState('');
    const [siteContactEmail, setSiteContactEmail] = useState('');
    const [incidentDetails, setIncidentDetails] = useState('');
    const [clientType, setClientType] = useState(null);
    const [jobCategory, setJobCategory] = useState(null);
    const [otherJobCategory, setOtherJobCategory] = useState('');
    const [targetStart, setTargetStart] = useState(new Date());
    const [targetFinish, setTargetFinish] = useState(moment().add(7, 'days').toDate());
    const [workOrderNumber, setWorkOrderNumber] = useState('');
    const [notes, setNotes] = useState('');
    const [isOther, setIsOther] = useState(false);
    const [message, setMessage] = useState('');
    const [isProgrammedAccount, setIsProgrammedAccount] = useState(false);
    const [isTotalConstructionProgrammed, setIsTotalConstructionProgrammed] = useState(false);
    const [samsPlanFile, setSamsPlanFile] = useState(null);
    const [uploadMessage, setUploadMessage] = useState('');

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const autocompleteRef = useRef(null);

    const accountsOptions = [
        { value: 'programmed-facility-management', label: 'Programmed Facility Management' },
        { value: 'total-construction-maintenance-programmed', label: 'Total Construction Maintenance Solutions - Programmed' },
        { value: 'total-construction-maintenance-dhs', label: 'Total Construction Maintenance Solutions - DHS' },
        { value: 'total-construction-maintenance-chl', label: 'Total Construction Maintenance Solutions - CHL' },
        { value: 'yarra-valley-water-technical-assessing', label: 'Yarra Valley Water (Technical Assessing)' },
        { value: 'pattersons-build-group', label: 'Pattersons Build Group' },
    ];

    const clientTypesOptions = [
        { value: 'owner', label: 'Owner' },
        { value: 'tenant', label: 'Tenant' },
        { value: 'landlord', label: 'Landlord' },
        { value: 'authorisedContact', label: 'Authorised Contact' },
        { value: 'unknown', label: 'Unknown' },
    ];

    const generalJobCategoriesOptions = [
        { value: 'waterRestoration', label: 'Water/Flood Restoration' },
        { value: 'mouldRemediation', label: 'Mould Remediation' },
        { value: 'fireRestoration', label: 'Fire & Smoke Restoration' },
        { value: 'crimeScene', label: 'Crime Scene & Forensic Cleaning' },
        { value: 'buildingMaintenance', label: 'Building Maintenance' },
        { value: 'sewageBackflowRestoration', label: 'Sewage Backflow Restoration' },
        { value: 'other', label: 'Other, please specify' },
    ];

    const programmedJobCategoriesOptions = [
        { value: 'makeSafeStorm', label: 'Make Safe - Storm' },
        { value: 'makeSafeStormEvent', label: 'Make Safe - Storm Event' },
        { value: 'makeSafeMould', label: 'Make Safe - Mould' },
        { value: 'makeSafeFlood', label: 'Make Safe - Flood' },
        { value: 'makeSafeSewerage', label: 'Make Safe - Sewerage' },
        { value: 'makeSafeVandalismBreakIn', label: 'Make Safe - Vandalism/Break-in' },
        { value: 'makeSafeFire', label: 'Make Safe - Fire' },
        { value: 'makeSafeStructural', label: 'Make Safe - Structural' },
        { value: 'makeSafeBurstPipe', label: 'Make Safe - Burst Pipe' },
        { value: 'makeSafeCarImpact', label: 'Make Safe - Car Impact' },
        { value: 'makeSafeHazardousMaterial', label: 'Make Safe - Hazardous Material' },
        { value: 'makeSafeTemporaryHire', label: 'Make Safe - Temporary Hire' },
        { value: 'makeSafeSinkholeGroundMovement', label: 'Make Safe - Sinkhole/Ground Movement' },
        { value: 'approvedFTFWorks', label: 'Approved FTF Works' },
        { value: 'approvedRemediationWorks', label: 'Approved Remediation Works' },
        { value: 'approvedReinstatementWorks', label: 'Approved Reinstatement Works' },
        { value: 'approvedCAPWorks', label: 'Approved CAP Works' },
        { value: 'other', label: 'Other, please specify' },
    ];

    const fetchLastWorkOrderNumber = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/work-orders/last-number`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch last work order number');
            }
            const data = await response.json();
            const lastWorkOrderNumber = data.lastWorkOrderNumber;
            const nextWorkOrderNumber = generateNextWorkOrderNumber(lastWorkOrderNumber);
            setWorkOrderNumber(nextWorkOrderNumber);
        } catch (error) {
            console.error('Error fetching last work order number:', error);
        }
    };

    const generateNextWorkOrderNumber = (lastWorkOrderNumber) => {
        if (!lastWorkOrderNumber) {
            return 'AEN02001';
        }
        const prefix = 'AEN';
        const lastNumber = parseInt(lastWorkOrderNumber.replace('AEN', ''), 10);
        const nextNumber = (lastNumber + 1).toString().padStart(5, '0');
        return `${prefix}${nextNumber}`;
    };

    useEffect(() => {
        fetchLastWorkOrderNumber();
    }, []);

    const handleSetCurrentDateTime = () => {
        setDateTime(new Date());
    };

    const handleAccountChange = (selectedOption) => {
        setAccount(selectedOption);
        const isProgrammed = selectedOption.value === 'programmed-facility-management';
        const isTotalConstructionProgrammed = selectedOption.value === 'total-construction-maintenance-programmed';

        setIsProgrammedAccount(isProgrammed);
        setIsTotalConstructionProgrammed(isTotalConstructionProgrammed);
    };

    const handleJobCategoryChange = (selectedOption) => {
        setJobCategory(selectedOption);
        setIsOther(selectedOption.value === 'other');
    };

    const handleTargetStartChange = (date) => {
        setTargetStart(date);
        setTargetFinish(moment(date).add(7, 'days').toDate());
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!account) {
            setMessage('Account is required.');
            return;
        }

        if (isProgrammedAccount && !jobNumber) {
            setMessage('Client Work Order No./Job No. is required for Programmed Facility Management.');
            return;
        }

        const token = localStorage.getItem('token');
        let newWorkOrderNumber = workOrderNumber;

        const workOrderData = {
            dateTime: moment(dateTime).format('DD-MM-YYYY HH:mm'),
            account: account?.value,
            jobNumber,
            poNumber,
            address: isManualAddress
                ? `${manualAddress.street}, ${manualAddress.city}, ${manualAddress.state}, ${manualAddress.zip}`
                : address,
            contactName,
            contactPhone,
            contactMobile,
            siteContactEmail: isProgrammedAccount ? siteContactEmail : undefined,
            incidentDetails: isProgrammedAccount ? incidentDetails : undefined,
            clientType: !isProgrammedAccount ? clientType?.value : undefined,
            jobCategory: jobCategory?.label === 'Other, please specify' ? otherJobCategory : jobCategory?.label,
            targetStart: moment(targetStart).format('DD-MM-YYYY HH:mm'),
            targetFinish: moment(targetFinish).format('DD-MM-YYYY HH:mm'),
            workOrderNumber: isProgrammedAccount ? jobNumber : newWorkOrderNumber,
            notes,
            description: notes,
            detailsOfIncident: incidentDetails,
        };

        try {
            const endpoint = isProgrammedAccount
                ? `${process.env.REACT_APP_API_URL}/admin/programmed-work-orders`
                : `${process.env.REACT_APP_API_URL}/admin/work-orders`;

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(workOrderData)
            });

            if (!response.ok) {
                throw new Error('Failed to create work order');
            }

            if (samsPlanFile) {
                setUploadMessage('Uploading SAMS Plan file...');
                const uploadResponse = await uploadSamsPlanFile(samsPlanFile, jobNumber, token);
                if (!uploadResponse.ok) {
                    throw new Error('Failed to upload SAMS Plan file');
                }

                const s3Url = uploadResponse.url;
                await saveSamsPlanUrlToDatabase(jobNumber, s3Url, token);
                setUploadMessage('SAMS Plan file uploaded successfully!');
            }

            setMessage('Work order created successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 1500);

        } catch (error) {
            console.error('Error creating work order:', error);
            setMessage('Error creating work order');
            setUploadMessage('');
        }
    };

    const uploadSamsPlanFile = async (file, workOrderNumber, token) => {
        const folderName = `${workOrderNumber}_PRG_SAMs`;

        const formData = new FormData();
        formData.append("file", file);
        formData.append("folder", folderName);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-sams-plan/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });

        if (response.ok) {
            const s3Url = await response.text();
            return { ok: true, url: s3Url };
        } else {
            return { ok: false };
        }
    };

    const saveSamsPlanUrlToDatabase = async (workOrderNumber, s3Url, token) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/save-sams-plan-url`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ workOrderNumber, samsPlanUrl: s3Url }),
        });
        return response;
    };
    const onPlaceChanged = useCallback(() => {
        if (autocompleteRef.current) {
            const place = autocompleteRef.current.getPlace();

            if (place.name && place.formatted_address) {
                // Combine place name and formatted address
                const fullAddress = `${place.name}, ${place.formatted_address}`;
                setAddress(fullAddress);  // Save the full address including place name
            } else if (place.formatted_address) {
                setAddress(place.formatted_address);  // If no name, fallback to the formatted address
            } else {
                setAddress(autocompleteRef.current.input.value);  // Fallback to input field value if needed
            }
        }
    }, []);





    return (
        <>
            <AdminHeader />
            <div className="form-container">
                <h2>New Work Order</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Date/Time PO Received:</label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <DatePicker
                                selected={dateTime}
                                onChange={setDateTime}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={1}
                                dateFormat="dd-MM-yyyy HH:mm"
                                timeCaption="Time"
                            />
                            <button
                                type="button"
                                onClick={() => setDateTime(new Date())}
                                className="set-current-time-button"
                            >
                                Set Current Time
                            </button>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Account:</label>
                        <Select options={accountsOptions} onChange={handleAccountChange} value={account} required />
                    </div>
                    <div className="form-group">
                        <label>Client Work Order No./Job No.:</label>
                        <input type="text" value={jobNumber} onChange={(e) => setJobNumber(e.target.value)} required={isProgrammedAccount} />
                    </div>

                    <div className="form-group">
                        <label>
                            {isProgrammedAccount || isTotalConstructionProgrammed
                                ? 'Service Request No.'
                                : 'Client Purchase Order No.'}
                        </label>
                        <input
                            type="text"
                            value={poNumber}
                            onChange={(e) => setPoNumber(e.target.value)}
                        />
                    </div>
                    {/* Job Category for Programmed Facility Management */}
                    {isProgrammedAccount && (
                        <div className="form-group">
                            <label>Job Category:</label>
                            <Select
                                options={programmedJobCategoriesOptions}
                                onChange={handleJobCategoryChange}
                                value={jobCategory}
                            />
                        </div>
                    )}
                    <div className="form-group">
                        <label>Address:</label>
                        {isManualAddress ? (
                            <div>
                                <input
                                    type="text"
                                    placeholder="Street"
                                    value={manualAddress.street}
                                    onChange={(e) => setManualAddress({ ...manualAddress, street: e.target.value })}
                                />
                                <input
                                    type="text"
                                    placeholder="City"
                                    value={manualAddress.city}
                                    onChange={(e) => setManualAddress({ ...manualAddress, city: e.target.value })}
                                />
                                <input
                                    type="text"
                                    placeholder="State"
                                    value={manualAddress.state}
                                    onChange={(e) => setManualAddress({ ...manualAddress, state: e.target.value })}
                                />
                                <input
                                    type="text"
                                    placeholder="ZIP Code"
                                    value={manualAddress.zip}
                                    onChange={(e) => setManualAddress({ ...manualAddress, zip: e.target.value })}
                                />
                                <button type="button" onClick={() => setIsManualAddress(false)}>Use Google Maps</button>
                            </div>
                        ) : (
                            <div>

                            {isLoaded && (
                                        <Autocomplete
                                            onLoad={ref => autocompleteRef.current = ref}
                                            onPlaceChanged={onPlaceChanged}
                                            options={{
                                                componentRestrictions: { country: 'au' },
                                                types: ['geocode', 'establishment']
                                            }}
                                        >
                                            <input
                                                type="text"
                                                placeholder="Enter an address"
                                                style={{ width: '100%' }}
                                                value={address}  // Bind the input field to the state
                                                onChange={(e) => setAddress(e.target.value)}  // Allow manual input
                                            />
                                        </Autocomplete>
                                    )}



                                <button type="button" onClick={() => setIsManualAddress(true)}>Enter Address Manually</button>
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label>{isProgrammedAccount ? 'Site Contact (Full Name & Position)' : 'Contact\'s Name'}:</label>
                        <input type="text" value={contactName} onChange={(e) => setContactName(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>{isProgrammedAccount ? 'Site Contact Mobile Number' : 'Contact\'s Phone Number'}:</label>
                        <input type="tel" value={contactPhone} onChange={(e) => setContactPhone(e.target.value)} />
                    </div>
                    {isProgrammedAccount ? (
                        <>
                            <div className="form-group">
                                <label>Site Contact Email Address:</label>
                                <input type="email" value={siteContactEmail} onChange={(e) => setSiteContactEmail(e.target.value)} />
                            </div>
                            <div className="form-group full-width">
                                <label>Details of the Incident/Event:</label>
                                <textarea value={incidentDetails} onChange={(e) => setIncidentDetails(e.target.value)} rows="14" />
                            </div>
                            <div className="form-group full-width">
                                <label>Description:</label>
                                <textarea value={notes} onChange={(e) => setNotes(e.target.value)} rows="20" />
                            </div>
                            <div className="form-group full-width">
                                <label>Target Start:</label>
                                <DatePicker
                                    selected={targetStart}
                                    onChange={handleTargetStartChange}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                />
                            </div>
                            <div className="form-group full-width">
                                <label>Target Finish:</label>
                                <DatePicker
                                    selected={targetFinish}
                                    onChange={(date) => setTargetFinish(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                />
                            </div>
                            <div className="form-group">
                                <label>SAMS Plan:</label>
                                <input type="file" accept=".pdf,.jpg,.jpeg,.png,.doc,.docx" onChange={(e) => setSamsPlanFile(e.target.files[0])} />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="form-group">
                                <label>Contact's Mobile Number:</label>
                                <input type="tel" value={contactMobile} onChange={(e) => setContactMobile(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>Client Type:</label>
                                <Select options={clientTypesOptions} onChange={setClientType} value={clientType} />
                            </div>
                            <div className="form-group">
                                <label>Job Category:</label>
                                {isOther ? (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="text"
                                            value={otherJobCategory}
                                            onChange={(e) => setOtherJobCategory(e.target.value)}
                                            placeholder="Please specify"
                                            style={{ marginRight: '10px' }}
                                        />
                                        <button type="button" onClick={() => setIsOther(false)}>Back</button>
                                    </div>
                                ) : (
                                    <Select
                                        options={isProgrammedAccount || isTotalConstructionProgrammed ? programmedJobCategoriesOptions : generalJobCategoriesOptions}
                                        onChange={handleJobCategoryChange}
                                        value={jobCategory}
                                    />
                                )}
                            </div>
                            <div className="form-group full-width">
                                <label>Description: </label>
                                <textarea
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                    rows="20"
                                />
                            </div>
                            <div className="form-group">
                                <label>Target Start:</label>
                                <DatePicker
                                    selected={targetStart}
                                    onChange={handleTargetStartChange}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                />
                            </div>
                            <div className="form-group">
                                <label>Target Finish:</label>
                                <DatePicker
                                    selected={targetFinish}
                                    onChange={(date) => setTargetFinish(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                />
                            </div>
                        </>
                    )}
                    {!isProgrammedAccount && (
                        <div className="form-group">
                            <label>Work Order Number:</label>
                            <input type="text" value={workOrderNumber} readOnly />
                        </div>
                    )}
                    <button className="submit-work-order" type="submit">Submit</button>
                </form>
                {message && <p>{message}</p>}
                {uploadMessage && <p>{uploadMessage}</p>}
            </div>
            <AdminFooter />
        </>
    );
};

export default AddNewWorkOrder;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminDashboard from './AdminDashboard';
import WorkOrders from './WorkOrders';
import AddNewWorkOrder from './AddNewWorkOrder';
import WorkOrderView from './WorkOrderView';
import LoginPage from './LoginPage';
import PurchaseOrders from './PO Page/PurchaseOrders';
import InvoicesPage from './InvoiceTab/InvoicesPage';
import AddNewPurchaseorder from './PO Page/AddnewPurchaseOrder';
import WorkOrderCustomerDiscussion from './WorkorderCustomerDiscussion/WorkOrderCustomerDiscussion';
import CustomerDiscussionDetails from './WorkorderCustomerDiscussion/CustomerDiscussionDetails';
import ResourceAllocation from './ResourceAllocation/ResourceAllocation';
import PurchaseOrderView from './PO Page/PurchaseOrderView';
import CompletedJobs from './Completed/CompletedJobs';
import './App.css';
import ProtectedRoute from './ProtectedRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/admin" element={<ProtectedRoute element={AdminDashboard} />} />
        <Route path="/admin/work-orders" element={<ProtectedRoute element={WorkOrders} />} />
        <Route path="/admin/work-orders/add-new-work-order" element={<ProtectedRoute element={AddNewWorkOrder} />} />
        <Route path="/admin/work-orders/:workOrderNo" element={<ProtectedRoute element={WorkOrderView} />} />
        <Route path="/admin/programmed-work-orders/:workOrderNo" element={<ProtectedRoute element={WorkOrderView} />} />
        <Route path="/admin/purchase-orders" element={<ProtectedRoute element={PurchaseOrders} />} />
        <Route path="/admin/purchase-orders/add-new-purchase-order" element={<ProtectedRoute element={AddNewPurchaseorder} />} />
        <Route path="/admin/purchase-orders/:purchaseOrderNumber" element={<ProtectedRoute element={PurchaseOrderView} />} />
        <Route path="/admin/programmed-purchase-orders/:purchaseOrderNumber" element={<ProtectedRoute element={PurchaseOrderView} />} /> {/* Route for programmed purchase orders */}
        <Route path="/admin/ready-to-invoice" element={<ProtectedRoute element={InvoicesPage} />} />
        <Route path="/admin/completed-jobs" element={<ProtectedRoute element={CompletedJobs} />} />
        <Route path="/admin/work-order-customer-discussion" element={<ProtectedRoute element={WorkOrderCustomerDiscussion} />} />
        <Route path="/admin/work-order-customer-discussion/:workOrderNo" element={<ProtectedRoute element={CustomerDiscussionDetails} />} />
        <Route path="/admin/resource-allocation" element={<ProtectedRoute element={ResourceAllocation} />} />
        <Route path="*" element={<ProtectedRoute element={AdminDashboard} />} /> {/* Fallback route */}
      </Routes>
    </Router>
  );
}

export default App;

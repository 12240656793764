import React from 'react';

const AdminSummaryPanel = ({ title, count }) => {
  const handleClick = () => {
    // Add your click event logic here
    console.log('Summary panel clicked!');
  };

  return (
    <button className="summary-panel" onClick={handleClick}>
      <div className="count">{count}</div>
      <div className="title">{title}</div>
    </button>
  );
};

export default AdminSummaryPanel;

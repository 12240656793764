import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, compareAsc } from 'date-fns';
import styled from '@emotion/styled';
import '../AdminDashboard.css';
import './ResourceAllocation.css';

const StyledFormControl = styled(FormControl)`
  margin-top: 16px;
  margin-bottom: 8px;
`;

const StyledInputLabel = styled(InputLabel)`
  background-color: #fff;
  padding-right: 4px;
  padding-left: 4px;
`;

const StyledSelect = styled(Select)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
`;

const ScheduleTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    border: 3px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #005b5b;
    color: white;
  }

  .busy {
    background-color: #f8d7da;
  }

  .free {
    background-color: #d4edda;
  }

  .technician-name {
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
  }

  .technician-row {
    background-color: #d3d3d3;
    font-weight: bold;
  }

  .technician-row:nth-of-type(odd) {
    background-color: #e0e0e0;
  }

  .technician-row:nth-of-type(even) {
    background-color: #c0c0c0;
  }
`;

function ResourceAllocation() {
  const { id } = useParams();

  const [value, setValue] = useState(0);
  const [workOrder, setWorkOrder] = useState('');
  const [resources, setResources] = useState([]);
  const [scopeOfWorks, setScopeOfWorks] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [finishTime, setFinishTime] = useState(null);
  const [scheduledBy, setScheduledBy] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const [workOrders, setWorkOrders] = useState([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const workOrderNumbers = [
    'AEN02001_Melbourne Gardens Primary School_TCMS - Programmed',
    'AEN02002-Bridgewater_Elementary-Horizon Middle School_TCMS-DHS',
    'AEN02003_Lakeside High-Crystal Lake College-Patterson Build Group',
    'AEN02004_Springfield Academy-Elmwood School_TCMS-CHL',
  ];
  const technicianNames = ['Aji', 'John', 'Sam', 'Peter'];
  const schedulers = ['Sam', 'Aji'];

  const technicianRefs = useRef(technicianNames.reduce((acc, name) => {
    acc[name] = React.createRef();
    return acc;
  }, {}));

  useEffect(() => {
    const savedWorkOrders = localStorage.getItem('workOrders');
    if (savedWorkOrders) {
      setWorkOrders(JSON.parse(savedWorkOrders));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('workOrders', JSON.stringify(workOrders));
  }, [workOrders]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCurrentTime = () => {
    const currentTime = new Date();
    const formattedTime = format(currentTime, 'MMMM d, yyyy h:mm aa');
    setCurrentTime(formattedTime);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!workOrder) {
      window.alert('Please fill in the Work Order No');
      return;
    }
    if (resources.length === 0) {
      window.alert('Please select at least one Resource');
      return;
    }
    if (!scopeOfWorks) {
      window.alert('Please fill in the Scope of Works');
      return;
    }
    if (!startTime) {
      window.alert('Please select the Start Time');
      return;
    }
    if (!finishTime) {
      window.alert('Please select the Finish Time');
      return;
    }
    if (!scheduledBy) {
      window.alert('Please select who Scheduled By');
      return;
    }
    if (!currentTime) {
      window.alert('Please add the Current Time');
      return;
    }

    const newWorkOrder = {
      workOrder,
      scheduledBy,
      resources,
      scopeOfWorks,
      dateScheduled: currentTime,
      startTime: format(startTime, 'MMMM d, yyyy h:mm aa'),
      finishTime: format(finishTime, 'MMMM d, yyyy h:mm aa'),
    };

    const updatedWorkOrders = [...workOrders];
    const existingWorkOrderIndex = updatedWorkOrders.findIndex(
      (order) => order.workOrder === workOrder
    );

    if (existingWorkOrderIndex > -1) {
      updatedWorkOrders[existingWorkOrderIndex].schedules.push(newWorkOrder);
    } else {
      updatedWorkOrders.push({
        workOrder,
        schedules: [newWorkOrder],
      });
    }

    setWorkOrders(updatedWorkOrders);
    localStorage.setItem('workOrders', JSON.stringify(updatedWorkOrders));

    setWorkOrder('');
    setResources([]);
    setScopeOfWorks('');
    setStartTime(null);
    setFinishTime(null);
    setScheduledBy('');
    setCurrentTime('');
  };

  const handleWorkOrderClick = (workOrder) => {
    setSelectedWorkOrder(workOrder);
  };

  const getSchedulesForDate = (technician, date) => {
    const selectedDateStr = format(date, 'MMMM d, yyyy');
    return workOrders
      .flatMap((order) => order.schedules)
      .filter(
        (schedule) =>
          schedule.resources.includes(technician) &&
          format(new Date(schedule.startTime), 'MMMM d, yyyy') === selectedDateStr
      );
  };

  const scrollToTechnician = (name) => {
    if (technicianRefs.current[name]) {
      technicianRefs.current[name].current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <AdminHeader />
      <Box sx={{ width: '100%' }}>
        <Paper elevation={3}>
          <Tabs value={value} onChange={handleChange} aria-label="resource allocation tabs">
            <Tab label="+ Schedule Resource" />
            <Tab label="Work Order" />
            <Tab label="Resources" />
          </Tabs>
          {value === 0 && (
            <Box component="form" className="resource-allocation-form" sx={{ padding: 2 }} onSubmit={handleSubmit}>
              <StyledFormControl fullWidth margin="normal" variant="outlined">
                <StyledInputLabel id="work-order-label">Work Order No</StyledInputLabel>
                <StyledSelect
                  labelId="work-order-label"
                  value={workOrder}
                  onChange={(e) => setWorkOrder(e.target.value)}
                  label="Work Order No"
                >
                  {workOrderNumbers.map((order) => (
                    <MenuItem key={order} value={order}>
                      {order}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </StyledFormControl>
              <StyledFormControl fullWidth margin="normal" variant="outlined">
                <StyledInputLabel id="resources-label">Resources</StyledInputLabel>
                <StyledSelect
                  labelId="resources-label"
                  multiple
                  value={resources}
                  onChange={(e) => setResources(e.target.value)}
                  label="Resources"
                >
                  {technicianNames.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </StyledFormControl>
              <TextField
                fullWidth
                multiline
                rows={4}
                margin="normal"
                value={scopeOfWorks}
                onChange={(e) => setScopeOfWorks(e.target.value)}
                label="Scope Of Work"
                variant="outlined"
              />
              <StyledFormControl fullWidth margin="normal" variant="outlined">
                <DatePicker
                  selected={startTime}
                  onChange={(date) => setStartTime(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="Select Start Time"
                  customInput={<TextField label="Start Time" variant="outlined" />}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth margin="normal" variant="outlined">
                <DatePicker
                  selected={finishTime}
                  onChange={(date) => setFinishTime(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="Select Finish Time"
                  customInput={<TextField label="Finish Time" variant="outlined" />}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth margin="normal" variant="outlined">
                <StyledInputLabel id="scheduled-by-label">Scheduled By</StyledInputLabel>
                <StyledSelect
                  labelId="scheduled-by-label"
                  value={scheduledBy}
                  onChange={(e) => setScheduledBy(e.target.value)}
                  label="Scheduled By"
                >
                  {schedulers.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </StyledFormControl>
              <TextField
                fullWidth
                label="Current Time"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                value={currentTime}
                readOnly
                variant="outlined"
              />
              <Button variant="contained" onClick={handleCurrentTime} sx={{ backgroundColor: '#005B5B', marginBottom: 2 }}>
                Add Current Time
              </Button>
              <div style={{ textAlign: 'center' }}>
                <Button type="submit" variant="contained" sx={{ backgroundColor: '#005B5B' }}>
                  Submit
                </Button>
              </div>
            </Box>
          )}
          {value === 1 && (
            <Box sx={{ padding: 2 }}>
              <h2>Work Orders</h2>
              {selectedWorkOrder ? (
                <Box>
                  <Button className="back-to-list" onClick={() => setSelectedWorkOrder(null)} sx={{ marginBottom: 2 }}>
                    Back to List
                  </Button>
                  <h3>Work Order No: {selectedWorkOrder.workOrder}</h3>
                  {selectedWorkOrder.schedules
                    .sort((a, b) => compareAsc(new Date(a.startTime), new Date(b.startTime)))
                    .map((schedule, index) => (
                      <Box key={index}>
                        <h4>Schedule {index + 1}</h4>
                        <Paper className="schedule-card">
                          <Box className="schedule-details">
                            <p><strong>Scheduled By:</strong> {schedule.scheduledBy}</p>
                            <p><strong>Resources Selected:</strong> {schedule.resources.join(', ')}</p>
                            <p><strong>Scope of Work:</strong> {schedule.scopeOfWorks}</p>
                            <p><strong>Date Scheduled:</strong> {schedule.dateScheduled}</p>
                            <p><strong>Start Time:</strong> {schedule.startTime}</p>
                            <p><strong>Finish Time:</strong> {schedule.finishTime}</p>
                          </Box>
                        </Paper>
                      </Box>
                    ))}
                </Box>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>Work Order No</th>
                      <th>Scheduled By</th>
                      <th>Resources Selected</th>
                      <th>Date Scheduled</th>
                      <th>Start Time</th>
                      <th>Finish Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workOrders.map((order, index) => (
                      <tr key={index}>
                        <td>
                          <Button
                            variant="text"
                            onClick={() => handleWorkOrderClick(order)}
                          >
                            {order.workOrder}
                          </Button>
                        </td>
                        <td>{order.schedules && order.schedules[0] && order.schedules[0].scheduledBy}</td>
                        <td>{order.schedules && order.schedules[0] && order.schedules[0].resources.join(', ')}</td>
                        <td>{order.schedules && order.schedules[0] && order.schedules[0].dateScheduled}</td>
                        <td>{order.schedules && order.schedules[0] && order.schedules[0].startTime}</td>
                        <td>{order.schedules && order.schedules[0] && order.schedules[0].finishTime}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Box>
          )}
          {value === 2 && (
            <Box sx={{ padding: 2 }}>
              <h2>Resources</h2>
              <Box sx={{ textAlign: 'right', marginBottom: 2 }}>
                {technicianNames.map((name) => (
                  <Button
                    key={name}
                    variant="contained"
                    sx={{ margin: '0 5px', backgroundColor: '#005B5B' }}
                    onClick={() => scrollToTechnician(name)}
                  >
                    {name}
                  </Button>
                ))}
              </Box>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="MMMM d, yyyy"
                customInput={<Button variant="contained" sx={{ marginBottom: 2 }}>Select Date</Button>}
              />
              <ScheduleTable>
                <thead>
                  <tr>
                    <th>Time slots</th>
                    <th>Schedule</th>
                  </tr>
                </thead>
                <tbody>
                  {technicianNames.map((technician, techIndex) => {
                    const schedules = getSchedulesForDate(technician, selectedDate);
                    const timeSlots = Array.from({ length: 24 }, (_, i) => ({
                      time: `${i}:00 - ${i + 1}:00`,
                      workOrder: null,
                    }));

                    schedules.forEach((schedule) => {
                      const startHour = new Date(schedule.startTime).getHours();
                      const endHour = new Date(schedule.finishTime).getHours();
                      for (let i = startHour; i <= endHour; i++) {
                        timeSlots[i].workOrder = schedule.workOrder;
                      }
                    });

                    return (
                      <React.Fragment key={technician}>
                        <tr className="technician-row" ref={technicianRefs.current[technician]}>
                          <td className="technician-name" colSpan={2}>{technician}</td>
                        </tr>
                        {timeSlots.map((slot, index) => (
                          <tr key={index} className={slot.workOrder ? 'busy' : 'free'}>
                            <td>{slot.time}</td>
                            <td>{slot.workOrder || 'Free'}</td>
                          </tr>
                        ))}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </ScheduleTable>
            </Box>
          )}
        </Paper>
      </Box>
      <AdminFooter />
    </div>
  );
}

export default ResourceAllocation;
